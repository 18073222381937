@import './src/app/styles/variables';

/* You can add global styles to this file, and also import other style files */
@import '../node_modules/normalize-scss/sass/normalize';
@include normalize();

// Angular Calendar Styles
@import '../node_modules/angular-calendar/css/angular-calendar.css';

a {
  text-decoration: none;
  color: inherit;
}

body {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

body,
button,
input,
optgroup,
select,
textarea {
  font-family: $font-family;
}

html .page-wrapper {
  // height is 100% of view port
  // minus the height of the navbar
  height: calc(100vh - 64px);

  // main page content padding
  .page-content {
    height: calc(100vh - 64px);
    padding: 30px;
  }
}

.portal-btn {
  font-size: 15px;
  font-weight: 400;

  &.small {
    min-width: 40px !important;
  }

  .mat-button-wrapper {
    color: #fff;
  }

  &.btn-primary {
    background-color: $app-primary;
  }

  &.btn-upload {
    background-color: $app-upload;
  }

  &.btn-warn {
    background-color: $app-remove-item;
  }

  &.btn-bright {
    background: linear-gradient(210deg, $app-highlight, $app-lowlight);

    &[disabled][disabled] {
      background: rgba(0, 0, 0, 0.26);
    }
  }

  &.btn-toast {
    .undo & {
      background-color: #3f8073;
    }

    .success & {
      background-color: rgb(63, 130, 63);
    }

    .error & {
      background-color: rgb(160, 46, 40);
    }

    .info & {
      background-color: rgb(35, 116, 141);
    }

    .warning & {
      background-color: rgb(194, 115, 5);
    }
  }

  &.btn-outline {
    background: none;
    color: $app-lowlight;
    border: 2px solid $app-lowlight;
  }

  &.btn-md {
    font-size: 12px;
    padding: 8px 10px;

    &.btn-bright {
      padding: 10px 13px;
    }
  }

  &.btn-lg {
    font-size: 1.4em;
    padding: 14px 20px;

    &.btn-bright {
      padding: 14px 20px;
    }
  }
}

mat-icon {
  &.success {
    color: $app-add-item;
  }

  &.error {
    color: $app-error;
  }

  &.warning {
    color: $app-warning;
  }
}

.mat-checkbox-input:disabled,
.mat-checkbox-disabled {
  .mat-checkbox-background {
    background-color: #d0d0d0;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px;
    border-color: #909090;
  }
}

.options-panel {
  padding: 0 10px;

  &.mat-menu-panel {
    border-radius: 2px;
    background-color: $app-primary;
    margin-top: 1px;
    padding: 0;
    .mat-menu-content {
      padding: 0;
    }
  }

  button.mat-menu-item {
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-bottom: 1px solid rgba(235, 218, 255, 0.789);

    &:last-child {
      border-bottom: none;
    }
  }
  button.mat-menu-item.mat-menu-item-submenu-trigger::after {
    color: #fff;
  }
}

mat-icon.mat-icon.material-icons {
  &.small {
    height: 12px;
    width: 12px;
    font-size: 1.25em;
  }

  &.medium {
    height: 18px;
    width: 18px;
    font-size: 1.5em;
  }
}

mat-form-field {
  .mat-form-field-outline {
    background-color: $app-white;
    border-radius: 4px;
  }
}

// Styles required for angular calendar
// =======================================
.medicine {
  span.cal-event {
    background-color: $app-highlight;
  }
}

.appointment {
  span.cal-event {
    background-color: $app-primary;
  }
}

.appointment.provisional {
  span.cal-event {
    background-color: $app-warning;
  }
}

.video-call {
  span.cal-event {
    background-color: $app-add-item;
  }
}

.hidden {
  span.cal-event {
    background-color: $app-inactive;
  }
}

.cal-event {
  &.medicine {
    background-color: $app-highlight;
  }

  &.appointment {
    background-color: $app-primary;
  }

  &.appointment.provisional {
    background-color: $app-warning;
  }

  &.video-call {
    background-color: $app-add-item;
  }

  &.hidden {
    background-color: $app-inactive;
  }
}

.cal-cell .cal-day-badge {
  background-color: $app-lowlight;
}

.calendar-container .cal-month-view .cal-open-day-events {
  background-color: #ededed;
  box-shadow: none;
  color: $app-lowlight;
}

// =======================================

// Styles for radio buttons
// =======================================
.group-filter {
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $app-primary;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $app-primary;
  }
}

.message-body-container {
  .ps-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: calc(
      100vh - (#{$app-toolbar-height} * 2) - (#{$menu-padding} * 2) - 200px -
        34px
    );
  }

  &.large .ps-content {
    min-height: 200px;
  }
}

// Styles for Quill Text Editor
// =======================================
.ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: $app-bg-darker;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
  min-height: 150px;
  max-height: 300px;
}

// =======================================

// ngx-toastr style overides
// =======================================
.toast-container div[portal-toast-component].toast {
  position: relative;
  overflow: hidden;
  padding: 0; // remove padding
  margin: 0;
  width: 500px; // increase width
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
}

// =======================================

// Styles for HelpScout Beacon
// =======================================
.BeaconFabButtonFrame {
  margin-bottom: 30px;
}

.BeaconContainer {
  margin-bottom: 25px;
}

#beacon-container {
  position: relative;
  z-index: 1;
}

// ===============================================================

// Custom style for Dialog modal, to be added on modal creation
// ===============================================================
.reduced-padding-dialog .mat-dialog-container {
  padding: 12px;
}

.full-screen-dialog .mat-dialog-container {
  padding: 0;
  border-radius: 0;
}

// ===============================================================

// Override fab button
// ===============================================================
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: $app-primary !important;
}

.portal-btn.mat-raised-button {
  min-width: auto;
}

// ===============================================================

// Override default react container div styling from React.createElement -> root
// ===============================================================
.portal-unauthorised {
  width: 100%;
  height: 100%;
}
